import { message } from 'antd'
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosPromise,
} from 'axios'
import qs from 'qs'
import ApiLoading from '@/components/apiLoading'
import URLLIST from './urlList'

export const httpSuccess = (key: number | boolean) => {
  if (key === true) return true
  return false
}
const _axios = axios.create()
_axios.defaults.timeout = 60000
_axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded'
_axios.defaults.baseURL = '/api'

// 请求拦截
_axios.interceptors.request.use((config: AxiosRequestConfig<any>) => {
  // 判断token并添加到请求头
  const token = localStorage.getItem('token')
  if (token) (config.headers as any)['token'] = token
  if (config.params?.h1) {
    // config.params?.h1 请求过程操作，如显示请求中loading等
    ApiLoading.show()
    delete config.params.h1
  }
  return config
})

// 响应拦截
_axios.interceptors.response.use(
  (res: AxiosResponse<any, any>) => {
    setTimeout(() => {
      if (ApiLoading?.type !== 'always') ApiLoading.hide()
      if (res.data.code === 200 || res.data.status) {
        // ApiLoading.hide()
        // 请求成功
      } else {
        // 错误统一处理
        ApiLoading.hide()
        res.data.reason && message.error(res.data.reason)
      }
    }, 0)
    return res
  },
  (err: AxiosError) => {
    ApiLoading.hide()
    message.error('服务器开小差了。。。。')
    // 处理错误
    // return Promise.reject(err)
    return err
  }
)

// 根据参数获取真实路径
function initApiConfig<T extends object, K extends keyof T>(
  urlList: T,
  key: K
): T[K] | K {
  return urlList[key] ?? key
}

// 请求方法配置
export default {
  axios: _axios,
  get: (
    key: HttpUrlKey,
    params?: any,
    config?: Omit<AxiosRequestConfig, 'parmas'>
  ): AxiosPromise<any> => {
    let { url, ...data } = params || {}
    if (!url) url = initApiConfig(URLLIST, key)
    return _axios.get(url, { params: data, ...config })
  },
  post: (
    key: HttpUrlKey,
    data?: AxiosRequestConfig<any>,
    config?: AxiosRequestConfig<any>
  ): AxiosPromise<any> => {
    const url: string = initApiConfig(URLLIST, key)
    return _axios.post(url, data, config)
  },
  postForm: (
    key: HttpUrlKey,
    data?: AxiosRequestConfig<any>,
    config?: AxiosRequestConfig<any>
  ): AxiosPromise<any> => {
    let { url, ...params } = data || {}
    if (!url) url = initApiConfig(URLLIST, key)
    return _axios.post(url, qs.stringify(params, { arrayFormat: 'repeat' }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      ...config,
    })
  },
  del: (
    key: HttpUrlKey,
    params?: any,
    config?: Omit<AxiosRequestConfig, 'parmas'>
  ): AxiosPromise<any> => {
    let url: string = initApiConfig(URLLIST, key)
    // url/params 方式
    if (params?.url) url = params.url
    return _axios.delete(url, { params, ...config })
  },
}

/*
// let params = [1, 2, 3];

// indices(默认)
qs.stringify({a: params}, {
  arrayFormat: 'indices'
})
// 结果是
'a[0]=1&a[1]=2&a[2]=3'

// brackets
qs.stringify({a: params}, {
  arrayFormat: 'brackets'
})
// 结果是
'a[]=1&a[]=2&a[]=3'

// repeat
qs.stringify({a: params}, {
  arrayFormat: 'repeat'
})
// 结果是
'a=1&a=2&a=3'
*/
