import { createElement } from 'react'

import AsyncLoader from '../loadable'
import type { RouteType } from '../index'

const rotues: RouteType[] = [
  {
    path: 'login',
    name: '登录',
    element: createElement(AsyncLoader(() => import('@/pages/login'))),
  },
]

export default rotues
