import { createElement } from 'react'

import AsyncLoader, { RouterView } from '../loadable'
import type { RouteType } from '../index'

const rotues: RouteType[] = [
  {
    path: 'report',
    name: '统计报表',
    limitId: 181,
    element: <RouterView />,
    children: [
      {
        index: true,
        path: 'comprehensiveStatistics',
        name: '综合统计',
        limitId: 138,
        element: createElement(
          AsyncLoader(
            () => import('@/pages/main/report/comprehensiveStatistics')
          )
        ),
      },
      // {
      //   path: 'books/:type/:projectType/:id',
      //   name: '账本详情',
      //   hideInMenu: true,
      //   element: createElement(
      //     AsyncLoader(() => import('@/pages/main/funds/books/detail'))
      //   ),
      // },
      {
        path: 'accountReport',
        name: '统计报告',
        limitId: 187,
        element: createElement(
          AsyncLoader(() => import('@/pages/main/report/accountReport'))
        ),
      },
    ],
  },
]

export default rotues
