import { createElement } from 'react'

import AsyncLoader, { RouterView } from '../loadable'
import type { RouteType } from '../index'

const rotues: RouteType[] = [
  {
    path: 'system',
    element: <RouterView />,
    name: '系统管理',
    limitId: 29,
    children: [
      {
        path: 'main/system',
        element: createElement(
          AsyncLoader(() => import('@/pages/main/system/attachment'))
        ),
      },
      {
        path: 'userManage',
        element: createElement(
          AsyncLoader(() => import('@/pages/main/system/userManage'))
        ),
        limitId: 30,
        name: '用户管理',
      },
      {
        path: 'roles',
        element: createElement(
          AsyncLoader(() => import('@/pages/main/system/roles'))
        ),
        limitId: 31,
        name: '角色管理',
      },
      {
        path: 'attachment',
        element: createElement(
          AsyncLoader(() => import('@/pages/main/system/attachment'))
        ),
        limitId: 185,
        name: '附件类型管理',
      },
      {
        path: 'flow',
        element: createElement(
          AsyncLoader(() => import('@/pages/main/system/flow'))
        ),
        limitId: 126,
        name: '流程模型管理',
      },
      {
        path: 'systemConfig',
        element: createElement(
          AsyncLoader(() => import('@/pages/main/system/systemConfig'))
        ),
        limitId: 127,
        name: '系统配置管理',
      },
      {
        path: 'configOption',
        element: createElement(
          AsyncLoader(() => import('@/pages/main/system/configOption'))
        ),
        limitId: 33,
        name: '通用配置管理',
      },
      {
        path: 'templateManage',
        element: createElement(
          AsyncLoader(() => import('@/pages/main/system/templateManage'))
        ),
        limitId: 32,
        name: '模板管理',
      },
    ],
  },
]

export default rotues
