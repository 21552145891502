import { Navigate } from 'react-router-dom'
import {
  RouteTypeWithMiddleware,
  useMiddlewareRoutes,
} from 'react-router-middleware-plus'

/** 登录相关路由 */
import userRotues from './config/user'
/** 业务路由 */
import mainRotues from './config/main'

const getFlatRoutes = (routes: RouteType[], parentPath = ''): RouteType[] => {
  let list: RouteType[] = []

  routes.forEach((item) => {
    const res = {
      ...item,
    }

    if (item.path) {
      res.path = `${parentPath}/${item.path}`
    }

    list.push(res)

    if (item.children) {
      list = list.concat(getFlatRoutes(item.children, res.path))
    }
  })

  return list
}

export type RouteType = Omit<RouteTypeWithMiddleware, 'children'> & {
  /** 路由名称 */
  name?: string
  /** 是否在菜单隐藏 */
  hideInMenu?: boolean
  children?: RouteType[]
  limitId?: number // 权限id
}

export const routes: RouteType[] = [
  ...userRotues,
  ...mainRotues,
  {
    path: '*',
    element: <Navigate to='/main/home' />,
  },
]

export const flatRoutes = getFlatRoutes(routes)

export default function AppRouter() {
  return useMiddlewareRoutes(routes)
}
