import { createElement } from 'react'

import AsyncLoader, { RouterView } from '../loadable'
import type { RouteType } from '../index'

const rotues: RouteType[] = [
  {
    path: 'project',
    name: '项目管理',
    limitId: 19,
    element: <RouterView />,
    children: [
      {
        path: 'all',
        index: true,
        name: '项目汇总',
        limitId: 20,
        element: createElement(
          AsyncLoader(() => import('@/pages/main/project/all'))
        ),
      },
      {
        path: 'detail',
        name: '项目详情',
        hideInMenu: true,
        limitId: 25,
        element: createElement(
          AsyncLoader(() => import('@/pages/main/project/all/hxxm/detail'))
        ),
      },
      {
        path: 'czDetail',
        name: '项目详情',
        hideInMenu: true,
        limitId: 27,
        element: createElement(
          AsyncLoader(() => import('@/pages/main/project/all/czhxxm/detail'))
        ),
      },
      {
        path: 'selfDetail',
        name: '项目详情',
        hideInMenu: true,
        limitId: 134,
        element: createElement(
          AsyncLoader(() => import('@/pages/main/project/all/zyxm/detail'))
        ),
      },
      {
        path: 'specialDetail',
        name: '项目详情',
        hideInMenu: true,
        limitId: 180,
        element: createElement(
          AsyncLoader(() => import('@/pages/main/project/all/czzxxm/detail'))
        ),
      },
      {
        path: 'pending',
        name: '我的待办',
        index: true,
        limitId: 20, // 和项目取相同权限
        element: createElement(
          AsyncLoader(() => import('@/pages/main/project/pending'))
        ),
      },
      // {
      //   path: 'reviewMeeting',
      //   name: '评审会管理',
      //   element: createElement(
      //     AsyncLoader(() => import('@/pages/main/project/reviewMeeting'))
      //   ),
      // },
      // {
      //   path: 'reviewDetail',
      //   name: '评审会详情',
      //   element: createElement(
      //     AsyncLoader(() => import('@/pages/main/project/reviewMeeting/detail'))
      //   ),
      // },
    ],
  },
]

export default rotues
