import { createElement } from 'react'

import AsyncLoader, { RouterView } from '../loadable'
import type { RouteType } from '../index'

const rotues: RouteType[] = [
  {
    path: 'funds',
    name: '经费管理',
    element: <RouterView />,
    limitId: 132,
    children: [
      {
        index: true,
        path: 'books',
        name: '账本管理',
        limitId: 23,
        element: createElement(
          AsyncLoader(() => import('@/pages/main/funds/books'))
        ),
      },
      {
        path: 'books/:type/:projectType/:id',
        name: '账本详情',
        hideInMenu: true,
        limitId: 137,
        element: createElement(
          AsyncLoader(() => import('@/pages/main/funds/books/detail'))
        ),
      },
      {
        path: 'books/checkDetails',
        name: '查看明细',
        hideInMenu: true,
        element: createElement(
          AsyncLoader(() => import('@/pages/main/funds/books/checkDetails'))
        ),
      },
      {
        path: 'spendRegister',
        name: '报销登记',
        limitId: 133,
        element: createElement(
          AsyncLoader(() => import('@/pages/main/funds/spendRegister'))
        ),
      },
      {
        path: 'cert',
        name: '凭证管理',
        limitId: 26,
        element: createElement(
          AsyncLoader(() => import('@/pages/main/funds/cert'))
        ),
      },
      {
        path: 'projectFundsDetail', // 路由路径
        name: '项目财政收支明细',
        limitId: 24,
        element: createElement(
          AsyncLoader(() => import('@/pages/main/funds/projectFundsDetail'))
        ),
      },
      // {
      //   path: 'fundsActionAllTable',
      //   name: '经费执行情况一览表',
      //   element: createElement(
      //     AsyncLoader(() => import('@/pages/main/funds/fundsActionAllTable'))
      //   ),
      // },
      {
        path: 'fundsPreWarning',
        name: '经费预警',
        limitId: 135,
        element: createElement(
          AsyncLoader(() => import('@/pages/main/funds/fundsPreWarning'))
        ),
      },
      // {
      //   path: 'projectCheckAccount',
      //   name: '项目对账',
      //   element: createElement(
      //     AsyncLoader(() => import('@/pages/main/funds/projectCheckAccount'))
      //   ),
      // },
      {
        path: 'reuseStatistics',
        name: '重用统计',
        limitId: 9999, // 需求变更需要删除改模快，正常权限136，使用不正确权限隐藏该功能
        element: createElement(
          AsyncLoader(() => import('@/pages/main/funds/reuseStatistics'))
        ),
      },
    ],
  },
]

export default rotues
