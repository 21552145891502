import { createElement } from 'react'

import AsyncLoader from '../loadable'
import type { RouteType } from '../index'

const rotues: RouteType[] = [
  {
    index: true,
    path: 'home',
    name: '首页',
    limitId: 18,
    element: createElement(AsyncLoader(() => import('@/pages/main/home'))),
  },
]

export default rotues
