import { createElement } from 'react'

import AsyncLoader from '../loadable'
import type { RouteType } from '../index'

const rotues: RouteType[] = [
  {
    path: 'archives',
    name: '档案管理',
    limitId: 174,
    element: createElement(AsyncLoader(() => import('@/pages/main/archives'))),
  },
  {
    path: 'archives/:id',
    name: '档案详情',
    hideInMenu: true,
    limitId: 174,
    element: createElement(
      AsyncLoader(() => import('@/pages/main/archives/detail'))
    ),
  },
]

export default rotues
