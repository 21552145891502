import React from 'react'
// import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd'
import moment from 'moment'
import zhcn from 'antd/lib/locale/zh_CN'
import 'moment/locale/zh-cn'
import App from './App'
import store from './store'
import reportWebVitals from './reportWebVitals'
import './assets/css/index.scss'

moment.locale('zh-cn')

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  // <React.StrictMode>
  <ConfigProvider locale={zhcn}>
    <Provider store={store}>
      <App />
    </Provider>
  </ConfigProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
