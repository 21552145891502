import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Axios, { httpSuccess } from '@/http'
import { logout } from './auth'

export interface LeaderItems {
  list?: any[]
  dictLoading?: 'loading' | 'finish' | 'error'
}

const initialState: LeaderItems = {
  // list: [],
}

// 异步Action
export const getAsyncLeaderList = createAsyncThunk(
  'leaderList/getAsyncUserList',
  async (query: Record<string, any>) => {
    const res = await Axios.post('getAllLeader', { ...query })
    if (httpSuccess(res.data.status)) {
      const { records } = res.data
      return records
    }
  }
)

export const leaderListSlice = createSlice({
  name: 'leaderList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // 进行请求阶段的一些操作
    builder.addCase(getAsyncLeaderList.pending, (state) => {
      state.dictLoading = 'loading'
    })

    builder.addCase(getAsyncLeaderList.rejected, (state) => {
      state.dictLoading = 'error'
    })
    builder.addCase(getAsyncLeaderList.fulfilled, (state, action) => {
      state.dictLoading = 'finish'
      state.list = action.payload
    })
    builder.addCase(logout, (state) => {
      state.dictLoading = undefined
      state.list = undefined
    })
  },
})
export default leaderListSlice.reducer
