import { createElement, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from '@/http'

import AsyncLoader from '../loadable'
import type { RouteType } from '../index'

/** 首页 */
import homeRotues from './home'
/** 项目管理 */
import projectRotues from './project'
/** 档案管理 */
import archivesRotues from './archives'
/** 经费管理 */
import fundsRotues from './funds'
/** 项目人员管理 */
import personRotues from './person'
/** 统计报表 */
// import statementRotues from './statement'
/** 系统管理 */
import systemRotues from './system'
/** 统计报表 */
import reportRoutes from './report'

// 是否登录,组件式中间件
const CheckLogin = ({ children }: any) => {
  const navigate = useNavigate()
  const [isLogin, setIsLogin] = useState<boolean | undefined>(undefined)
  useEffect(() => {
    checkLogin()
  }, [])
  const checkLogin = async () => {
    const res = await Axios.get('checkLogin')
    if (res?.data?.status === false) {
      navigate('/login', { replace: true })
    } else {
      setIsLogin(true)
    }
  }
  if (!isLogin) {
    return null
  }
  return children
}

// 是否有权限，函数式中间件
const CheckAuthority = ({ children }: any) => {
  const isAdmin = true

  // 通过鉴权
  return isAdmin ? children : null
}

const rotues: RouteType[] = [
  {
    path: 'main',
    element: createElement(AsyncLoader(() => import('@/pages/main'))),
    middleware: [CheckLogin, CheckAuthority],
    children: [
      /** 首页 */
      ...homeRotues,
      /** end 首页 */

      /** 项目管理 */
      ...projectRotues,
      /** end 项目管理 */

      /** 档案管理 */
      ...archivesRotues,
      /** end 档案管理 */

      /** 经费管理 */
      ...fundsRotues,
      /** end 经费管理 */

      /** 项目人员管理 */
      ...personRotues,
      /** end 项目人员管理 */

      // /** end 统计报表 */
      // ...statementRotues,
      // /** end 统计报表 */

      /** 系统管理 */
      ...systemRotues,
      /** end 系统管理 */

      /** 统计报表 */
      ...reportRoutes,
      /** end 统计报表 */
    ],
  },
]

export default rotues
