import React from 'react'
// import { ReduxRouter } from '@lagunovsky/redux-react-router'
import { history, HistoryRouter } from '@/router/history'
import AppRouter from './router'

function App() {
  return (
    <HistoryRouter history={history}>
      <AppRouter />
    </HistoryRouter>
  )
}

export default App
