import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { keyBy } from 'lodash'
import Axios, { httpSuccess } from '@/http'
import { logout } from './auth'
// import { getDictAction } from '@/store/actionCreators'
interface DictItem {
  id: string | number
  optionName: string
  optionValue: string
  priority: number
  system: number
}
export interface Dict {
  dictAll?: {
    [key: string]: {
      id: string
      groupKey: string
      groupName: string
      configOptionDetailVOList: DictItem[]
    }
  }
  loading?: 'loading' | 'finish' | 'error'
}

const initialState: Dict = {
  dictAll: {},
}

// 异步Action
export const getAsyncDict = createAsyncThunk('dict/getAsyncDict', async () => {
  const res = await Axios.get('dictAll')
  if (httpSuccess(res.data.status)) {
    const { records } = res.data
    return records
  }
})

export const dictSlice = createSlice({
  name: 'dict',
  initialState,
  reducers: {
    resetState: (state) => {
      state = {}
    },
  },
  extraReducers: (builder) => {
    // 进行请求阶段的一些操作
    builder.addCase(getAsyncDict.pending, (state, action) => {
      state.loading = 'loading'
    })
    builder.addCase(getAsyncDict.fulfilled, (state, action) => {
      state.dictAll = keyBy(action.payload, 'groupName')
    })
    builder.addCase(getAsyncDict.rejected, (state) => {
      state.loading = 'error'
    })
    builder.addCase(logout, (state) => {
      state.dictAll = {}
      state.loading = undefined
    })
  },
})
export const { resetState } = dictSlice.actions
export default dictSlice.reducer
