import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Axios, { httpSuccess } from '@/http'
import { logout } from './auth'

export interface UserList {
  list?: any[]
  dictLoading?: 'loading' | 'finish' | 'error'
}

const initialState: UserList = {
  // list: [],
}

// 异步Action
export const getAsyncUserList = createAsyncThunk(
  'userList/getAsyncUserList',
  async (query: Record<string, any>) => {
    const res = await Axios.get('userList', { deptId: '1', ...query })
    if (httpSuccess(res.data.status)) {
      const { records } = res.data
      return records
    }
  }
)

export const userListSlice = createSlice({
  name: 'userList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // 进行请求阶段的一些操作
    builder.addCase(getAsyncUserList.pending, (state) => {
      state.dictLoading = 'loading'
    })

    builder.addCase(getAsyncUserList.rejected, (state) => {
      state.dictLoading = 'error'
    })
    builder.addCase(getAsyncUserList.fulfilled, (state, action) => {
      state.dictLoading = 'finish'
      state.list = action.payload
    })
    builder.addCase(logout, (state) => {
      state.dictLoading = undefined
      state.list = undefined
    })
  },
})
export default userListSlice.reducer
