import { configureStore } from '@reduxjs/toolkit'
// import appReducer from './slices'
import dict from './slices/dict'
import userList from './slices/userList'
import userinfo from './slices/auth'
import leader from './slices/leader'
const store = configureStore({
  reducer: {
    dict,
    userList,
    user: userinfo,
    leader,
    // appReducer,
    // posts: postsReducer,
    // comments: commentsReducer,
    // users: usersReducer,
  },
  // devTools: true,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
