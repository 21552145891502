import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Axios, { httpSuccess } from '@/http'
import { history } from '@/router/history'
const initialState: {
  userinfo?: UserInfo
  privilegeList?: string
  loading?: 'loading' | 'finish' | 'error'
} = {}
// 异步Action
export const getAsyncUser = createAsyncThunk(
  'auth/login',
  async (
    {
      type,
      redirect,
      userInfo,
    }: {
      type: 'login' | 'change'
      redirect?: string
      userInfo?: UserInfo
    },
    options
  ) => {
    const state: any = options.getState()
    // 获取权限列表
    let privilegeList: string | undefined

    // 用户权限判断
    if (!state?.privilegeList) {
      const limit = await Axios.get('myPermission')
      if (httpSuccess(limit?.data?.status)) {
        privilegeList = limit?.data?.detail
      }
    }

    console.log(redirect)

    // 用户信息判断
    if (userInfo) {
      if (type === 'login') {
        history.push(redirect || '/main/home')
      }

      return {
        userInfo: userInfo,
        privilegeList,
      }
    }

    const res = await Axios.post('getCurrentUserInfo')
    if (httpSuccess(res?.data?.status)) {
      if (type === 'login') {
        history.push(redirect || '/main/home')
      }
      return {
        userInfo: res?.data?.detail,
        privilegeList,
      }
    }
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    logout: (state) => {
      state.userinfo = {}
      state.loading = undefined
      state.privilegeList = undefined
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAsyncUser.fulfilled, (state, { payload }) => {
        state.userinfo = payload?.userInfo
        if (payload?.privilegeList) {
          state.privilegeList = payload?.privilegeList
        }
        state.loading = 'finish'
      })
      .addCase(getAsyncUser.rejected, (state, { payload }) => {
        state.loading = 'error'
      })
      .addCase(getAsyncUser.pending, (state, { payload }) => {
        state.loading = 'loading'
      })
  },
})
export const { logout } = authSlice.actions
export default authSlice.reducer
